.header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30%;
  background-color: #C6C7FF;
  color: #8469AD;
  height: 50px;

}

.headerright {
  display: flex;
  gap: 50px;


}

.loginbutton {
  display: flex;

}

.loginbutton .iconbox {
  background-color: #8469AD;
  width: 20px;
  height: 20px;
  border-radius: 50%;

}

.loginbutton button {
  background: none;
  border: none;
  color: #8469AD;
  font-weight: bold;
  font-size: large;
  cursor: pointer;
  transition: 1s;

  &:hover {
    color: #5E95BE;
    font-size: small;
  }
}

.cartbutton {
  display: flex;

}

.cartbutton button {

  background: none;
  border: none;
  color: #8469AD;
  font-weight: bold;
  font-size: large;
  cursor: pointer;

}

.cartbutton .iconbox {
  background-color: #8469AD;
  width: 20px;
  height: 20px;
  border-radius: 50%;

}

.navbar {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-content: center;
  gap: 200px;
  padding: 10px 0;
  margin-bottom: 20px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 5px 10px 1px #8469AD;
}

.logo {
  /* width: 30%; */
  text-align: center;
  /* display: flex; */
  /* justify-content: center !important; */
  /* background-color: #8469AD; */
}

.bar {
  display: flex;
  gap: 20px;
  align-items: center;
}

.bar a {

  text-decoration: none;
  color: #8469AD;
  font-size: large;
  font-weight: bold;
  position: relative;
  width: fit-content !important;
}

.list1 .iconbox {
  background-color: #8469AD;
  width: 20px;
  height: 20px;
  border-radius: 50%;


}

.list1 {
  gap: 10px;
  display: flex;
}

.aboutlist .iconbox {
  background-color: #8469AD;
  width: 20px;
  height: 20px;
  border-radius: 50%;


}

.aboutlist {
  gap: 10px;
  display: flex;
}

.bar button {
  border: none;
  padding: 12px 20px;
  border-radius: 29px;
  background-color: #5E95BE;
  color: white;
  font-weight: bold;
  transition: 1s ease;
  cursor: pointer;

  &:hover {
    padding: 15px 25px;

  }
}

.bar a::after {
  content: "";
  position: absolute;
  width: 0px;
  background-color: #8469AD;
  height: 3px;
  left: 0px;
  /* top: 0px; */
  bottom: -130%;
  transition: 0.5s ease;
}

.bar a:hover::after {

  width: 100%;

}

.footer {
  display: flex;
  flex-wrap: wrap;
  background-color: #C6C7FF;
  width: 100%;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px -5px 10px 1px #8469AD;
  background-image: url("https://yourgutmap.co.uk/wp-content/uploads/2024/02/bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.footerlogo {
  width: 100px;


}

.footerheader {
  display: flex;
  /* align-items: center; */
  justify-content: space-evenly;
  position: relative;
  height: 60vh;
  flex-wrap: wrap;
  width: 100%;

  &::after {
    content: "";
    width: 60%;
    position: absolute;
    height: 2px;
    /* top: 0px; */
    bottom: 0px;
    background-color: #8469AD;
  }

}

.lista ul li {
  list-style: none;
  font-size: large;
  /* font-weight: bolder; */
  font-weight: bold;
  line-height: 40px;

  color: black;

}

.lista {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #8469AD;
}

.footerend {

  display: flex;
  align-items: center;

  /* margin-top: 80px; */
  justify-content: space-around;
  color: #8469AD;
  padding: 20px 0;
}

.footerendleft a {
  font-weight: bold;
  text-decoration: none;

}

.logo img {

  width: 100px;

}

.footerlogo img {
  width: 200px;
  margin-top: 70px;
}

.navbaricons {
  display: none;
}
#navbarcheckbox {
  display: none;
}

@media screen and (max-width:1060px) {
  .bar {
    display: none;
    flex-direction: column;
    margin-top: 500px;
    background-color: #fff;
    height: fit-content;
    z-index: 100;
    padding: 50px;
    transform:1.5s ease;
    /* margin-right: 50px; */
  }

  .navbar {
    gap: 0;
    justify-content: space-between;
    position: relative;
  }

  .navbaricons {
    display: flex;
    position: absolute;
    right: 50px;
    top: 50px;
    color: #8469AD;

  }

  .open {
    display: flex;
  }

  .close {
    display: none;
  }



  #navbarcheckbox:checked~.open {
    display: none;
  }

  #navbarcheckbox:checked~.close {
    display: flex;
  }

  #navbarcheckbox:checked~.bar {
    display: flex;
  }

}