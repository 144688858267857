* {
    font-family: poppins;
    font-size: large;
    margin: 0px;
}



.header {

    width: 100%;
    height: 100px;
    background-color: #fff;
    /* margin-left: 20%; */
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;

}

h2 {
    font-size: x-large;

}

.header h3 {
    /* margin-top: 10px; */
    padding-top: 10px;
    line-height: 40px;
    font-size: x-large;
    margin-left: 20%;

}

.App {
    background-color: white;
    /* background-color: #e9f0f3; */
    /* background: linear-gradient(to bottom right, #d4cbea, #c6dcf3, #c5b4ed, #c6dcf3, #d5caf0, #c6dcf3, #c5b4ed, #c6dcf3); */

}

* {
    padding: 0;
    margin: 0;
}

.heading {
    margin-top: 30px;
    color: #6E4F9F;
    /* text-align: center; */
    line-height: 40px;
}

.searchbox {
    /* background-color: #4080B8; */
    display: flex;
    /* background-image: url('../public/bg.webp');
    background-size: cover;
    background-repeat: no-repeat; */
    /* justify-content: center;
    align-items: center; */
    border-radius: 50px;
    position: relative;
    /* text-align: center; */
    width: 60%;

    height: 100px;
    /* margin: 10px 15%; */

}

.hello {
    /* display: flex; */
    background-color: #EADFEF;
    width: 60%;
    /* gap: 100px; */
    /* display: flex;
    align-items: center;
    justify-content: center; */
    background-image: url("./../public/Screenshot\ 2024-12-06\ 155457.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 15%;
    padding: 50px 5%;
    border-radius: 10px;
}

.autocomple {
    border: none;
    width: 80% !important;
    height: 60px;
    border-radius: 50% !important;
    /* padding: 10px 50px; */
    margin-top: 20px;
}

.ant-select-selector {
    border-radius: 50px !important;
    box-shadow: 0 0 10px 10px #BDC4FD;
}

/* .searchbox input{
    border-radius: 50%;
} */
.search-icon {
    background-color: #6A4FA0;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 22%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    top: 30%;

    border-radius: 50%;
}

.box1 {
    /* background-color: #E2F2FD; */
    width: 66%;
    height: fit-content;
    color: #6E4E9F;
    margin: 20px 15%;
    /* background-image: url("../public/bg.webp");
    background-repeat: no-repeat;
    background-size: cover; */
    padding: 2%;
    text-align: center;
    /* box-shadow: rgb(204, 202, 202) 1px 5px 5px; */
    border-radius: 50px;
    z-index: 20;
    position: relative;
    /* 
    &::after {
        position: absolute;
        content: '';
        color: #437FB5;
        background-color: #00A6EC;
        width: 7PX;
        height: 80%;
        top: 39px;
        left: 0px;
    } */
}

.box1 h5+p {

    line-height: 50px;

}

.box1 p {
    align-items: center;
    display: flex;
}

.icon-box {
    background-color: #6A4FA0;
    width: 30px;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
}

.icon-box a {
    font-size: x-large;
    color: white;
}

.icons {
    gap: 10px;
    display: flex;
}

.boxfooter {

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    text-align: center;
    width: 96%;
    padding: 20px 2%;
    background-color: #cacef7;
    position: relative;
    border-radius: 10px;
    /* 
    &::after {

        content: '';
        position: absolute;
        color: gray;
        background-color: gray;
        width: 100%;
        height: 1px;
        top: -15px;

    } */

}

/* 
.boxright {

    margin-right: 100px;
} */

.box1 a {
    text-decoration: none;

    &:hover {

        text-decoration: line-through;

    }

}

.ticketdiv {
    color: white;
    display: flex;
    /* justify-content: center;
    align-items: center; */
    /* margin: 10vh 20%; */
    gap: 15px;

}

.ticketdivleft {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6A4FA0;
    /* gap: 20px; */
    width: fit-content;
    height: 40px;
    padding: 5px 10px;
    font-size: small;
    cursor: pointer;
    border-radius: 50px;
    /* box-shadow: rgb(196, 194, 194) 2px 2px 15px; */

    &:hover {
        box-shadow: none;

    }
}

.iconbox {

    /* background-color: #133E5E; */
    /* padding: 25px 25px; */
    border-radius: 50%;
}

.ticketdivleft span h6 {

    font-size: small !important;
    /* line-height: 20px; */
}

.ticketdivright {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6A4FA0;
    gap: 20px;
    border-radius: 50px;
    width: fit-content;
    padding: 5px 10px;
    height: 40px;
    cursor: pointer;
    /* box-shadow: rgb(196, 194, 194) 2px 2px 15px; */


    &:hover {

        box-shadow: none;

    }

}



.ticketdivright span h6 {
    font-size: small;

    /* line-height: 20px; */
}

.buttonhead {
    display: flex;
    align-items: center;
    justify-content: center;
}


.buttonmain {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.buttonmain button {
    padding: 5px 40px;
    color: #133E5E;
    background-color: rgb(198, 191, 191);
    border: none;
    font-weight: bold;
    font-size: medium;

    &:hover {

        background-color: #00A6EC;
        color: white;
    }

    &:visited {

        background-color: #ffff;

    }

}

.mainpage {

    padding-top: 50px;
    padding-bottom: 50px;
}

.toparticlesmaindiv {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    justify-content: center;
    align-items: center;
    /* width: 66%; */
}

.articlesdiv {

    background-color: #fff;
    width: 66%;
    height: fit-content;
    gap: 10px;
    margin: 2px 15%;
    border-left: 1px solid #6A4FA0;
    border-right: 1px solid #6A4FA0;
    border-top: 1px solid #6A4FA0;
    padding: 0.5%;
    color: #6A4FA0;
    border-radius: 1px;
    display: flex;
    border-radius: 20px;

    &:hover {
        background-color: #E2F2FD;

    }
}

.articlestext p {
    color: #4180B7;
}

.articlestext h3 {
    /* background-color: #DFDBFF; */
    width: 93%;
    min-height: 35px;
    height: fit-content;
    padding: 10px;
    /* color: #4180B7; */
    border-radius: 10px;

    &:active {
        color: #4180B7;
    }
}

.articlestext span {
    /* background-color: #DFDBFF; */
    width: 100%;
    /* height: 35px; */
}


.articlesdiv .articlestext {
    width: 100%;
    margin-left: 10px;
    display: flex;

    justify-content: space-between;
}

.enddiv {
    margin: 0px 10%;
    display: flex;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
}

.enddiv button {
    height: 50px;
    width: 200px;
    background-color: #fff;
    font-weight: bold;
    color: #00A6EC;
    border: 1px solid #00A6EC;

    &:hover {

        background-color: #00A6EC;
        color: white;

    }
}

.enddiv a {

    font-size: small;

    &:hover {
        text-decoration: none;

    }


}


.footerdiv {
    margin-top: 50px;
    background-color: #fff;
    text-align: center;
    padding: 15px;
    line-height: 30px;
}

.footerdiv p a:hover {

    text-decoration: none;

}

.ticketpage {
    padding-top: 30px;
    text-align: center;
    width: 100%;
    /* height: 75vh; */
    background-color: #CBC9FB;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ticket-box {
    width: 50%;
    color: #6E4E9F;
    background-color: white;
    border-radius: 20px;
    padding: 40px 5px;
    /* text-align: center; */
    margin: 100px 0px;
}

.mainbox {
    margin: 50px 0;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 50px;
}

.mainbox .mainbox1 {
    /* font-size: small; */
    height: 70px;
    cursor: pointer;
    background-color: white;
    width: 40%;
    display: flex;
    gap: 10px;
    border-radius: 10px;
    /* justify-content: center; */
    align-items: center;
    box-shadow: rgb(199, 196, 196) 2px 2px 10px;
    border: 1px solid #6A4FA0;

    &:hover {
        box-shadow: none;
        color: white;
        background-color: #6E4E9F;
    }


}

.mainbox .mainbox1 h4 {
    width: 100%;
    text-align: center;
}

.formpage {
    background-color: #CBC9FB;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    display: flex;
    /* text-align: center; */

}

.formpage form {
    margin: 100px auto;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    width: 50%;
    background-color: #fff;
    /* height: 50vh; */
    /* height: 100vh; */
    padding: 50px 50px;
    border-radius: 10px;
    color: #6A4FA0;
}

.cancel {

    display: flex;
    justify-content: end;
    align-items: end;
    width: 100%;

}

.formpage form input {

    width: 98%;
    height: 40px;
    border-radius: 10px;
    padding: 0 1%;
    /* margin-top: 20px; */
    background-color: #F3FAFF;
    border: #6E4E9F 1px solid;
}

.dropdown {
    /* align-items: center; */
    /* display: flex; */
    /* gap: 20px; */
    margin-top: 20px;

}

.dropdown label {
    width: 500px;

}

.dropdown .priorty {
    background: none;
    border: none;
    margin-left: 30px;
}


input[type="submit"] {
    height: 50px !important;
    width: 200px !important;
    margin: 20px auto;
    border: none;
    color: #fff;
    background-color: #6A4FA0 !important;
    font-size: large;
    font-weight: bold;

    &:hover {

        background-color: #00A6EC !important;

    }
}

input[type="file"] {
    /* padding-top: 0px; */
    padding: 0 0 !important;
    height: 30px;
    /* width: 150px !important; */
    border: none !important;
    border-radius: 5px;
    background-color: #fff !important;
    color: #6A4FA0 !important;
    cursor: pointer;
}


input[type="file"]::-webkit-file-upload-button {
    border: none;
    padding: 10px;
    width: 300px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #6A4FA0;
    color: white;

}


.formpage form textarea {

    height: 15vh;
    background-color: #F3FAFF;
    border: #6A4FA0 1px solid;
    border-radius: 20px;
    padding: 0 1%;
    width: 98%;

}

.formpage form label {

    margin-top: 30px;

}

.toparticlesfirstpagemaindiv {

    display: flex;
    min-height: 80vh;
    height: fit-content;
    /* align-items: center; */
    justify-content: center;
    width: 100%;
    padding: 5% 0 0 0;
    /* align-items: center; */
    gap: 10px;
    background-color: #CBC9FB;
}

.toparticlesfirstpageleft {

    background-color: #ffff;
    width: 45%;
    height: fit-content;
    /* margin-top: -50px; */
    margin-bottom: 100px;
    /* padding: 15px 25px; */
    font-size: small;
    line-height: 30px;
    border-radius: 10px;

}

.toparticlesfirstpagelefthead {
    background-color: #6A4FA0;
    padding: 30px;
    border-radius: 10px 10px 0 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.massege {
    /* background-color: #6A4FA0; */
    display: flex;
}

.messageboxmain {
    /* background-color: #6A4FA0; */
    padding: 20px;
}

.toparticlesfirstpageleft a:hover {
    text-decoration: none;


}

.toparticlesfirstpageright {

    /* margin-top: 20px; */
    width: 25%;
    /* height: 80vh; */
}

.toparticlesfirstpageright .articlesdetailbox {
    /* width: 100%; */
    background-color: #ffff;

    /* padding: 10px 20px; */
    border-radius: 10px;
}

.articlesdetailboxinside {
    color: #6A4FA0;
    padding: 20px;
    font-weight: 900;
}

.articlesdetailboxinside span {
    margin-bottom: 50px;
    /* background-color: #6A4FA0; */
}

.toparticlesfirstpageright .articlesdetailbox h4 {
    /* width: 100%; */
    color: #fff;
    text-align: center;
    background-color: #6A4FA0;
    padding: 10px 20px;
    border-radius: 10px 10px 0 0;
}

.toparticlesfirstpageright .articlesdetailbox span {
    font-size: small;
    display: flex;
    /* gap: 20px; */
    position: relative;
    margin-top: 20px;

    &::after {
        content: "";
        position: absolute;
        background-color: gray;
        width: 100%;
        height: 1px;
        bottom: -10px;

    }

}

.toparticlesfirstpageright .articlesdetailbox span p {
    width: 100px;

}

.toparticlesfirstpageright .articlesdetailbox span p+p {
    display: flex;
    width: 150px;

}

.toparticlesfirstpageright .articlesdetailbox .backbutton {
    margin-top: 30px;
    display: flex;
    justify-content: end;
}

.toparticlesfirstpageright .articlesdetailbox .backbutton a:hover {
    text-decoration: none;
}

.toparticlesfirstpageright .relatedarticlesbox {


    background-color: #fff;
    margin-top: 20px;
    padding: 30px 20px;
    line-height: 30px;


}

.searchmaindiv {


    height: 80vh;
}

.viewticketsmaindiv {
    width: 40%;
    height: 40vh;
    margin: auto;
    margin-top: 50px;
    background-color: #fff;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

.viewticketsmaindiv label {
    margin-right: 159PX;
    margin-top: 10PX;

    margin-bottom: -45PX;
}

.viewticketsmaindiv button {
    background-color: #6A4FA0;
    color: #fff;
    border: none;
    padding: 20px 50px;

    &:hover {

        background-color: #133E5E;


    }
}

.viewticketsmaindiv input {

    padding: 10px 60px;
    background-color: #cdd9e5;
    border: 1px solid #007BFF;


}

.viewtcketspage {

    width: 100%;
    height: 75vh;


}







.sharp-corner-box {
    margin-bottom: 20px;
    width: fit-content;
    padding: 10px;
    background-color: #CACEF7;
    /* border: 1px solid #ddd; */
    position: relative;
    color: #6A4FA0;
    border-radius: 10px;
}

.sharp-corner-box::before {
    content: '';
    position: absolute;
    top: 0px;
    left: -20px;
    width: 0;
    height: 0;
    border-left: 40px solid transparent;
    border-top: 40px solid #CACEF7;
}
.replyform{
    /* background-color: #6A4FA0; */
    display: flex;
    border-radius: 0 0 10px 10px ;
    position: relative;
}
.replyform textarea{
    background-color: #fff;
    display: flex;
    width: 100%;
    overflow: hidden;
    border-radius: 0 0 10px 10px ;
    padding: 0 10px;
    padding-right: 50px;
    outline-offset: 0;
    border: 1px solid gray;
}
.replyform button{
   position: absolute;
   right: 0;
   /* padding: 10px; */
   background: none;
   border: none;
   display: flex;
   justify-content: center;
   align-items: center;
   /* background-color: #00A6EC; */
   width: 60px;
   height: 60px;
}
.sharp-corner-box2 {
    width: fit-content;
    color: #fff;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #6A4FA0;
    /* border: 1px solid #ddd; */
    position: relative;
    border-radius: 10px;
    text-align: right;
}

.sharp-corner-box2::after {
    content: '';
    position: absolute;
    top: 0px;
    right: -20px;
    width: 0;
    height: 0;
    border-right: 40px solid transparent;
    border-top: 40px solid #6A4FA0;
}



@media screen and (max-width:1060px) {
    .searchbox {
        /* background-color: #4080B8; */
        display: flex;
        /* justify-content: center;
        align-items: center; */
        /* text-align: center; */
        width: 125%;
        height: 100px;
        margin: 0;
    }

    .AutoComplete {
        margin: 0;

        width: 100%;
    }

    .hello {
        padding: 5%;
        margin: 0;
        width: 90%;
        margin-bottom: 20px;
    }

    .box1 {
        background-color: #E2F2FD;
        width: 86%;
        height: fit-content;
        margin: 0;
        padding: 7%;
        box-shadow: rgb(204, 202, 202) 1px 5px 5px;
        position: relative;
    }

    .ticketdiv {
        color: #133E5E;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10vh 20%;
        /* gap: 100px; */
        flex-direction: column;
    }

    .ticketdivleft {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #6A4FA0;
        gap: 20px;
        color: white;
        width: 155%;
        height: 50px;
        padding: 5px 10px;
        cursor: pointer;
        box-shadow: rgb(196, 194, 194) 2px 2px 15px;
    }


    .ticketdivright {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #6A4FA0;
        gap: 20px;
        width: 155%;
        padding: 5px 10px;
        height: 50px;
        color: white;
        cursor: pointer;
        box-shadow: rgb(196, 194, 194) 2px 2px 15px;
    }

    .articlesdiv {
        background-color: #fff;
        width: 90%;
        height: fit-content;
        gap: 10px;
        margin: 0px 3%;
        padding: 2%;
        border-radius: 1px;
        display: flex;
    }

    .mainbox {
        margin: 0;
        justify-content: center;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 99%;
        gap: 50px;
    }

    .mainbox .mainbox1 {
        height: 70px;
        background-color: white;
        width: 90%;
        display: flex;
        gap: 10px;
        /* justify-content: center; */
        align-items: center;
        box-shadow: rgb(199, 196, 196) 2px 2px 10px;
    }

    .mainpage {
        overflow: hidden;
    }

    .ticket-box {
        width: 100%;


    }

    .formpage form {
        margin: 100px auto;
        /* align-items: center; */
        display: flex;
        flex-direction: column;
        width: 88%;
        background-color: #fff;
        /* height: 100vh; */
        padding: 50px 15px;
    }

    .viewticketsmaindiv {
        width: 97%;
        height: 40vh;
        margin: auto;
        margin-top: 50px;
        background-color: #fff;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }

    .toparticlesfirstpagemaindiv {
        display: flex;
        /* align-items: center; */
        justify-content: center;
        width: 100%;
        /* margin-top: 50px; */
        gap: 10px;
        flex-direction: column-reverse;
    }


    .toparticlesfirstpageright {
        /* margin-top: 20px; */
        width: 100%;
        height: fit-content;
    }

    .toparticlesfirstpageleft {
        background-color: #ffff;
        width: 87%;
        height: fit-content;
        /* margin-top: -50px; */
        /* margin-bottom: 100px; */
        padding: 15px 25px;
        font-size: small;
        line-height: 30px;
    }

    .boxright {
        margin-right: 0;
    }
}